import { render, staticRenderFns } from "./BookingGeneralSettingForm.vue?vue&type=template&id=22e33124&scoped=true&"
import script from "./BookingGeneralSettingForm.vue?vue&type=script&lang=ts&"
export * from "./BookingGeneralSettingForm.vue?vue&type=script&lang=ts&"
import style0 from "./BookingGeneralSettingForm.vue?vue&type=style&index=0&id=22e33124&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e33124",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VSheet,VSpacer,VSwitch,VTextField,VTextarea,VToolbar,VToolbarItems,VToolbarTitle,VTooltip})
